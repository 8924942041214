// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/Countdown.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/Countdown.tsx");
  import.meta.hot.lastModified = "1734124776000";
}
// REMIX HMR END

import React, { useEffect, useState } from 'react';
const Countdown = ({
  nextDeliveryDate
}) => {
  _s();
  const calculateTimeLeft = date => {
    if (!date) {
      return {};
    }
    const difference = +date - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(difference / (1000 * 60 * 60) % 24),
        minutes: Math.floor(difference / 1000 / 60 % 60)
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(nextDeliveryDate));
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(nextDeliveryDate));
    });
    return () => clearTimeout(timer);
  }, [nextDeliveryDate, timeLeft]);
  const renderCountdown = () => {
    if (!nextDeliveryDate || Object.keys(timeLeft).length === 0) {
      return '0 Tage 0 Std. 0 Min.';
    }
    const tl = timeLeft;
    return `${tl.days} Tage ${tl.hours} Std. ${tl.minutes} Min.`;
  };
  return <div style={{
    display: 'flex',
    alignItems: 'center'
  }}>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" style={{
        width: '1em',
        height: '1em',
        color: '#fafafa',
        marginRight: '0.5em'
      }}>
          <path fill="white" d="M0 32C0 14.3 14.3 0 32 0H64 320h32c17.7 0 32 14.3 32 32s-14.3 32-32 32V75c0 42.4-16.9 83.1-46.9 113.1L237.3 256l67.9 67.9c30 30 46.9 70.7 46.9 113.1v11c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 64 32c-17.7 0-32-14.3-32-32s14.3-32 32-32V437c0-42.4 16.9-83.1 46.9-113.1L146.7 256 78.9 188.1C48.9 158.1 32 117.4 32 75V64C14.3 64 0 49.7 0 32zM96 64V75c0 25.5 10.1 49.9 28.1 67.9L192 210.7l67.9-67.9c18-18 28.1-42.4 28.1-67.9V64H96zm0 384H288V437c0-25.5-10.1-49.9-28.1-67.9L192 301.3l-67.9 67.9c-18 18-28.1 42.4-28.1 67.9v11z" />
        </svg>
      </div>
      {renderCountdown()}
    </div>;
};
_s(Countdown, "FxQbXbw3vdtryQJVcFnZpKehPkQ=");
_c = Countdown;
export default Countdown;
var _c;
$RefreshReg$(_c, "Countdown");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;